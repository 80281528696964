import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/twistwood.jpg'),
        name: 'Twist Wood',
        type: 'Digital Durastone',
        size: 'Size : 300x300mm',
        placeholderImg: require('../../../assets/images/shop/twistwood_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/trillhl01a-th.jpg'),
        name: 'Trill HL 01 A',
        type: 'GLOSSY',
        placeholderImg: require('../../../assets/images/shop/trillhl01a_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/trevorcotto-tagtile.jpg'),
        name: 'Trevor Cotto',
        type: 'Digital Durastone',
        placeholderImg: require('../../../assets/images/shop/trevorcotto_tagtile_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/travertinobeige-tagtile.jpg'),
        name: 'Travertino Beige',
        type: 'MATT',
        placeholderImg: require('../../../assets/images/shop/travertinobeige-tagtile.jpg')
    },
    {
        img: require('../../../assets/images/shop/tacitus_light-a-th.jpg'),
        name: 'Tacitus Light',
        type: 'MATT',
        placeholderImg: require('../../../assets/images/shop/tacitus_light_a_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/782018840500.jpg'),
        name: 'Sohata Natural',
        type: 'Digital Durastone',
        placeholderImg: require('../../../assets/images/shop/782018840500.jpg')
    },
]

class FloorTiles extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/tiles.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Floor Tiles</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Floor Tiles<br /></h2>
                                        <p>Large collection of ceramic floor tiles in Azamgarh.</p>
                                        {/* <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.name}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholderImg} />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>{product.name}</h4>
                                                        <div className="product-cats">{product.type}</div>
                                                        <span className="product-price">Size : 300x300mm</span>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="content-nav">
                                            <ul>
                                                <a href="#/" className="cur-page"><span>End of list </span></a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                    {/* content-holder end */}
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Floor Tiles' />
            </>
        );
    }
}

export default FloorTiles;

import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Aboutus extends Component {
    render() {
        return (
            <section id="sec2" data-scrollax-parent="true">
                <div className="container">
                    <div className="section-container fl-wrap">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="content-wrap about-wrap">
                                    <h3 className="bold-title">About Trilokinath Agrawal & Sons</h3>
                                    <p>
                                        Trilokinath Agrawal & Sons is one of the top retail firm, established since 1931,
                                        located in Azamgarh, India. This company is listed in Trade India's list of verified sellers
                                        that provide the best design and superior quality of products and always focused on customer
                                        satisfaction. We have a vast customer base especially in ceramics, wall and floor tiles and
                                        sanitarywares. This company is also focused on educating our staff with the latest product
                                        knowledge and about industry marketing so that they can able to provide the best possible
                                        service to our customers.
                                    </p>
                                    <p>
                                        Trilokinath Agrawal & Sons is the retailer of Rudraksha Wall & Floor Tiles and SOCH
                                        Tiles & Sanitaryware in the Azamgarh location which deals with top brands for tiles,
                                        sanitaryware, bathroom fitting, UPVC, CPVC, PVC fittings, water tanks, submersible, geysers
                                        and so on. Rudraksha Ceramic was incorporated in the year 1991 by Mr. Raj Kumar Khandelwal
                                        and as a production unit holds a capacity of producing 40,000 sqm per day, 5 manufacturing
                                        units, a multi-layer distribution network of 2000 dealers and 1000 sub-dealers PAN India.
                                    </p>
                                    <a href="/about" className="btn float-btn flat-btn">Know More</a>
                                </div>
                            </div>
                            <div className="col-md-5">
                                    <LazyLoadImage
                                        alt='About us'
                                        src={require('../../../assets/images/about.jpg')}
                                        placeholderSrc={require('../../../assets/images/about_thumb.jpg')}
                                        effect="blur"
                                        width='100%' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg dec-bg left-pos-dec" data-bg={require('../../../assets/images/bg/14.jpg')} />
            </section>
        );
    }
}

export default Aboutus;
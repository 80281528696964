import React, { Component } from 'react';
import DealsIn from './dealsIn';
import Slider from './slider';
import ProductsHome from './products';
import AboutUs from './AboutUs';
import Sidebar from '../../core/sidebar'

class Home extends Component {

    render() {
        return (
            <>
                <div id="wrapper">
                    <Slider />
                    <div className="content-holder">
                        <div className="content">
                            <AboutUs />
                            <ProductsHome />
                            <DealsIn />
                        </div>
                    </div>
                </div>
                <Sidebar title='Home' />
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
            </>
        );
    }
}

export default Home;
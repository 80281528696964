import React, { Component } from 'react';

class Slider extends Component {
    render() {
        return (
            <div className="fs-gallery-wrap home-slider fl-wrap full-height" data-autoplayslider={5000}>
                <div className="slide-progress-container">
                    <div className="slide-progress-content">
                        <div className="slide-progress-warp">
                            <div className="slide-progress" />
                        </div>
                    </div>
                </div>
                <div className="swiper-container" data-scrollax-parent="true">
                    <div className="swiper-wrapper">
                        {/* slide 01 */}
                        <div className="swiper-slide">
                            <div className="bg" data-bg={require('../../../assets/images/slider/1.jpg')} data-scrollax="properties: { translateY: '250px' }" />
                            <div className="hero-wrap alt">
                                <div className="container">
                                    <div className="hero-item">
                                        <h3>Trilokinath Agrawal & Sons</h3>
                                        <h2>Established Since 1931</h2>
                                        <div className="clearfix" />
                                        <a href="/about" className="btn float-btn flat-btn">Know About Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* slide 02 */}
                        <div className="swiper-slide">
                            <div className="bg" data-bg={require('../../../assets/images/slider/2.jpg')} data-scrollax="properties: { translateY: '250px' }" />
                            <div className="hero-wrap alt">
                                <div className="container">
                                    <div className="hero-item">
                                        <h3>Make Your Bathroom Superior With</h3>
                                        <h2>Sanitary Ware </h2>
                                        <div className="clearfix" />
                                        <a href="/sanitary" className="btn float-btn flat-btn">View Sanitary Ware</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* slide 03 */}
                        <div className="swiper-slide">
                            <div className="bg" data-bg={require('../../../assets/images/slider/3.jpg')} data-scrollax="properties: { translateY: '250px' }" />
                            <div className="hero-wrap alt">
                                <div className="container">
                                    <div className="hero-item">
                                        <h3>Interior Design of</h3>
                                        <h2> Floor & Wall<br /> Tiles</h2>
                                        <div className="clearfix" />
                                        <a href="/tiles" className="btn float-btn flat-btn">View Tiles</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* slide 04 */}
                        <div className="swiper-slide">
                            <div className="bg" data-bg={require('../../../assets/images/slider/4.jpg')} data-scrollax="properties: { translateY: '250px' }" />
                            <div className="hero-wrap alt">
                                <div className="container">
                                    <div className="hero-item">
                                        <h3>Design your</h3>
                                        <h2> Interior Home <br /> As you want</h2>
                                        <div className="clearfix" />
                                        <a href="/gallery" className="btn float-btn flat-btn">View Portfolio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sw-button swiper-button-next"><i className="fa fa-angle-right" /></div>
                    <div className="sw-button swiper-button-prev"><i className="fa fa-angle-left" /></div>
                    <div className="swiper-pagination" />
                </div>
            </div>
        );
    }
}

export default Slider;

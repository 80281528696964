import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class ProductsHome extends Component {
    render() {
        return (
            <section className="homeProductssection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pr-title">
                                Our Products
                                <span>
                                    Wall Tiles | Floor Tiles | Parking Tiles | Elevation Tiles | SanitaryWare | Bathroom Fiting & Wellness | UPVC, CPVC, PVC Fittings | Water Tank | Submersible | Geysers
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5" />
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>01.</span>
                                    <ul>
                                        <li>Wall Tiles, Floor Tiles, Parking Tiles, Elevation Tiles</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                src={require('../../../assets/images/folio/tiles.jpg')}
                                 alt="Tiles"   
                                effect="blur"
                                width='100%'
                                placeholderSrc={require('../../../assets/images/folio/tiles_thumb.jpg')} />
                                <div className="parallax-text left-pos" data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>Tiles</h3><br />
                                    <a href="/tiles" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>02.</span>
                                    <ul>
                                        <li>Bath, Shower, Washbasin, Sink</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/sanitaryware.png')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%'
                                    placeholderSrc={require('../../../assets/images/folio/sanitaryware_thumb.jpg')} />
                                <div className="parallax-text right-pos"  data-scrollax="properties: { translateY: '-250px' }">
                                    <h3 id="sanitary">SanitaryWare</h3><br />
                                    <a href="/sanitary" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5" />
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>03.</span>
                                    <ul>
                                        <li>Bathtub, Shower, Washbasin, Sink</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/bathfit.jpg')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%' 
                                    placeholderSrc={require('../../../assets/images/folio/bathfit_thumb.jpg')} />
                                <div className="parallax-text left-pos" data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>Bathroom Fitting & Wellness</h3><br />
                                    <a href="/bathFit" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>04.</span>
                                    <ul>
                                        <li>PVC, CPVC, UPVC and HDPE are non metalic pipes</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/piping.jpg')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%'
                                    placeholderSrc={require('../../../assets/images/folio/pvc_thumb.jpg')} />
                                <div className="parallax-text right-pos" id="leftallign"data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>UPVC, CPVC, PVC Fittings</h3><br />
                                    <a href="/pvcFittings" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5" />
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>05.</span>
                                    <ul>
                                        <li>Our water storage solutions ensure highest standards of hygiene at a reasonable cost</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/Watertank.png')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%' 
                                    placeholderSrc={require('../../../assets/images/folio/watertank_thumb.jpg')}/>
                                <div className="parallax-text left-pos" data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>Water Tank</h3><br />
                                    <a href="/waterTank" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>06.</span>
                                    <ul>
                                        <li>Best Geyser / Water Heater Brands available</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/geysers.jpg')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%' 
                                    placeholderSrc={require('../../../assets/images/folio/geysers_thumb.jpg')} />
                                <div className="parallax-text right-pos" id="leftallign" data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>Geysers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3><br />
                                    <a href="/geysers" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5" />
                        <div className="col-md-7">
                            <div className="parallax-item fl-wrap" data-scrollax-parent="true">
                                <div className="parallax-header fl-wrap">
                                    <span>07.</span>
                                    <ul>
                                        <li>Buy high quality borewell Submersible Pumps</li>
                                    </ul>
                                </div>
                                <LazyLoadImage
                                    alt='Sanitaryware'
                                    src={require('../../../assets/images/folio/submersible.jpg')} // use normal <img> attributes as props
                                    effect="blur"
                                    width='100%'
                                    placeholderSrc={require('../../../assets/images/folio/submersible_thumb.jpg')} />
                                <div className="parallax-text left-pos" data-scrollax="properties: { translateY: '-250px' }">
                                    <h3>Submersible</h3><br />
                                    <a href="/submersible" className="btn float-btn flat-btn">View product</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="partcile-dec" data-parcount={250} />
            </section>
        );
    }
}

export default ProductsHome;

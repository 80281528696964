import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/havells1.png'),
        name: 'Instanio',
        type: '4500 W',
        desc: '3 L White Blue',
        placeholderImg: require('../../../assets/images/shop/havells1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells2.png'),
        name: 'Instanio',
        type: '4500 W',
        desc: '3 L White Mustard',
        placeholderImg: require('../../../assets/images/shop/havells2_thumb.jpg')

    },
    {
        img: require('../../../assets/images/shop/havells2.png'),
        name: 'Instanio',
        type: '3000 W',
        desc: '3 L White Mustard',
        placeholderImg: require('../../../assets/images/shop/havells2_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells1.png'),
        name: 'Instanio',
        type: '3000 W',
        desc: '3 L White Blue',
        placeholderImg: require('../../../assets/images/shop/havells1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells3.png'),
        name: 'Instanio',
        type: '4500 W',
        desc: '1 L White Mustard',
        placeholderImg: require('../../../assets/images/shop/havells3_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells4.png'),
        name: 'Instanio',
        type: '4500 W',
        desc: '1 L White Blue',
        placeholderImg: require('../../../assets/images/shop/havells4_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells5.png'),
        name: 'Opal Ec',
        type: '3000 W',
        desc: '3 L white',
        placeholderImg: require('../../../assets/images/shop/havells5_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells6.png'),
        name: 'Opal Ec',
        type: '4500 W',
        desc: '1 L white',
        placeholderImg: require('../../../assets/images/shop/havells6_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/havells6.png'),
        name: 'Opal Ec',
        type: '3000 W',
        desc: '1 L White ',
        placeholderImg: require('../../../assets/images/shop/havells6_thumb.jpg')
    },
]

class Geysers extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/geysers.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Geysers</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Geysers<br /></h2>
                                        <p>Large collection of Geysers in Azamgarh.</p>
                                        {/* <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.name}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholderImg}
                                                            className='productImg'
                                                        />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>{product.name}</h4>
                                                        <div className="product-cats">{product.type}</div>
                                                        <span className="product-price">{product.desc}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a href="#/" className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Geysers' />
            </>
        );
    }
}

export default Geysers;

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/home';
import Navbar from './core/navbar';
import Footer from './core/footer';
import Tiles from './pages/Products/Tiles';
import Contact from './pages/ContactUs/Contact';
import WallTiles from './pages/Products/WallTiles';
import FloorTiles from './pages/Products/FloorTiles';
import ParkingTiles from './pages/Products/ParkingTiles';
import ElevationTiles from './pages/Products/ElevationTiles';
import Geysers from './pages/Products/Geysers';
import Sanitaryware from './pages/Products/Sanitaryware';
import BathFit from './pages/Products/BathFit';
import pvcFittings from './pages/Products/pvcFittings';
import Gallery from './pages/Gallery/Gallery';
import WaterTank from './pages/Products/waterTank';
import Submersible from './pages/Products/submersible';

import About from './pages/AboutUs/AboutUs';

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/tiles" component={Tiles} />
          <Route exact path="/wallTiles" component={WallTiles} />
          <Route exact path="/floorTiles" component={FloorTiles} />
          <Route exact path="/parkingTiles" component={ParkingTiles} />
          <Route exact path="/elevationTiles" component={ElevationTiles} />
          <Route exact path="/geysers" component={Geysers} />
          <Route exact path="/sanitary" component={Sanitaryware} />
          <Route exact path="/bathFit" component={BathFit} />
          <Route exact path="/pvcFittings" component={pvcFittings} />
          <Route exact path="/waterTank" component={WaterTank} />
          <Route exact path="/submersible" component={Submersible} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/about" component={About} />
        </Switch>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;

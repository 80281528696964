import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/wash1.jpg'),
        name: 'Thin Rim Wall Hung Wash Basins',
        placeholderImg: require('../../../assets/images/shop/wash1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/wash2.jpg'),
        name: 'One piece wash basins with integrated half pedestal',
        placeholderImg: require('../../../assets/images/shop/wash2_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/ewc.jpg'),
        name: 'One piece EWCs',
        placeholderImg: require('../../../assets/images/shop/ewc.jpg')
    },
    {
        img: require('../../../assets/images/shop/ewc2.jpg'),
        name: 'Two piece wall hung EWCs',
        placeholderImg: require('../../../assets/images/shop/ewc2.jpg')
    },
    {
        img: require('../../../assets/images/shop/ewc3.jpg'),
        name: 'Back to wall EWC',
        placeholderImg: require('../../../assets/images/shop/ewc3.jpg')
    },
    {
        img: require('../../../assets/images/shop/urinal.png'),
        name: 'Urinal with integrated wash basin and sensor tap',
        placeholderImg: require('../../../assets/images/shop/urinal_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/urinal1.jpg'),
        name: 'Urinals',
        placeholderImg: require('../../../assets/images/shop/urinal1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/cisterns.jpg'),
        name: 'Ceramic cistern',
        placeholderImg: require('../../../assets/images/shop/cisterns.jpg')
    },
    {
        img: require('../../../assets/images/shop/cistern1.png'),
        name: 'Plastic cisterns',
        placeholderImg: require('../../../assets/images/shop/cistern1.png')
    },
]

class Sanitaryware extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/sanitaryware.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>SanitaryWare</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>SanitaryWare<br /></h2>
                                        <p>Large collection of SanitaryWare in Azamgarh.</p>
                                        {/* <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        {/* <LazyLoad debounce={100} placeholder={<img src={Placeholder} alt="placeholder" />}>
                                                            <img src={product.img} alt={product.name} />
                                                        </LazyLoad> */}
                                                        <LazyLoadImage
                                                            alt={product.name}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholderImg}
                                                            className='productImg' />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>Cera</h4>
                                                        <span className="product-price">{product.name}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a href="#/" className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Sanitary Ware' />
            </>
        );
    }
}

export default Sanitaryware;

import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/shower.jpg'),
        name: 'Cera',
        type: 'CORAL PREMIUM',
        placeholderImg: require('../../../assets/images/shop/shower_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/shower1.jpg'),
        name: 'Cera',
        type: 'CYPRUS PREMIUM',
        placeholderImg: require('../../../assets/images/shop/shower1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/faucet.jpg'),
        name: 'Cera',
        type: 'Fountain Single lever basin mixer',
        placeholderImg: require('../../../assets/images/shop/faucet_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/faucet1.jpg'),
        name: 'Cera',
        type: 'Fountain Single lever basin mixer extended',
        placeholderImg: require('../../../assets/images/shop/faucet1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/towel.jpg'),
        name: 'Cera',
        type: 'HERA Towel rail',
        placeholderImg: require('../../../assets/images/shop/towel_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/faucet4.jpeg'),
        name: 'Jaquar',
        type: '2 Way Bib Cock',
        placeholderImg: require('../../../assets/images/shop/faucet4_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/faucet5.jpeg'),
        name: 'Jaquar',
        type: '3-Hole Basin Mixer',
        placeholderImg: require('../../../assets/images/shop/faucet5_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/shower3.jpeg'),
        name: 'Jaquar',
        type: 'Alive Maze Hand Shower',
        placeholderImg: require('../../../assets/images/shop/shower3_thumb.jpg')
    },
]

class BathFit extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/bathfit.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Bathroom Fitting & Wellness</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Bathroom Fitting & Wellness<br /></h2>
                                        <p>Large collection of bathroom fitting products in Azamgarh.</p>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.type}
                                                            height='200px'
                                                            src={product.img}
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.p} />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>{product.name}</h4>
                                                        <span className="product-price">{product.type}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a href="#/" className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                    {/* content-holder end */}
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Bath fit' />
            </>
        );
    }
}

export default BathFit;

import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Sidebar from '../../core/sidebar'

const gallery = [
    {
        img: require('../../../assets/images/gallery/DSC_0026-min.JPG'),
        img_name: 'Tiles',
        img_title: 'tiles /Bathroom',
        url: '/#',
        img_class: 'gallery-item houses apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0026_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0032-min.JPG'),
        img_name: 'Modular Kitchen',
        img_title: ' Marbles/Kitchen',
        url: '/#',
        img_class: 'gallery-item houses interior',
        placeholderimg: require('../../../assets/images/gallery/dsc_0032_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_012-min.jpg'),
        img_name: 'Rudraksha Wall & Floor Tiles',
        img_title: 'Rudraksha/Showroom ',
        url: '/#',
        img_class: 'gallery-item  apartments interior',
        placeholderimg: require('../../../assets/images/gallery/dsc_012_min_thumb.jpg')
    },
    {

        img: require('../../../assets/images/gallery/DSC_0034-min.JPG'),
        img_name: 'Modular Walls',
        img_title: 'Walls Marble/Walls',
        url: '/#',
        img_class: 'gallery-item  interior design',
        placeholderimg: require('../../../assets/images/gallery/dsc_0034_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0037-min.JPG'),
        img_name: 'Digital Walls & Tiles',
        img_title: 'Wall /Digital Walls',
        url: '/#',
        img_class: 'gallery-item houses design',
        placeholderimg: require('../../../assets/images/gallery/dsc_0037_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0038-min.jpg'),
        img_name: 'Outdoor Tiles',
        img_title: 'Floor/Walls,Floor & tiles ',
        url: '/#',
        img_class: 'gallery-item houses',
        placeholderimg: require('../../../assets/images/gallery/dsc_0038_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0051-min.JPG'),
        img_name: 'Modular Tiles & Marbles',
        img_title: 'Walls/Walls,Floor & tiles ',
        url: '/#',
        img_class: 'gallery-item interior design',
        placeholderimg: require('../../../assets/images/gallery/dsc_0051_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0053-min.JPG'),
        img_name: 'Modular Walls',
        img_title: 'Walls/ Walls,Floor & Walls',
        url: '/#',
        img_class: 'gallery-item apartments interior',
        placeholderimg: require('../../../assets/images/gallery/dsc_0053_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0055-min.JPG'),
        img_name: 'Modular Floor & Marbles',
        img_title: 'Tiles/ Walls & tiles',
        url: '/#',
        img_class: 'gallery-item houses design',
        placeholderimg: require('../../../assets/images/gallery/dsc_0055_min_thumb.jpg')
    },
    {
        img: require('../../../assets/images/gallery/DSC_0104-min.JPG'),
        img_name: 'Modular Floor & Marbles',
        img_title: 'Tiles/ Walls',
        url: '/#',
        img_class: 'gallery-item design',
        placeholderimg: require('../../../assets/images/gallery/dsc_0104_min_thumb.jpg')
    },

    {
        img: require('../../../assets/images/gallery/DSC_0106-min.JPG'),
        img_name: 'Modular Floor & Tiles',
        img_title: 'Tiles/Walls & tiles ',
        url: '/#',
        img_class: 'gallery-item apartments interior',
        placeholderimg: require('../../../assets/images/gallery/dsc_0106_min_thumb.jpg')

    },
    {
        img: require('../../../assets/images/gallery/DSC_0110-min.JPG'),
        img_name: 'Modular Floor & Tiles',
        img_title: 'Walls/Walls & tiles',
        url: '/#',
        img_class: 'gallery-item houses',
        placeholderimg: require('../../../assets/images/gallery/dsc_0110_min_thumb.jpg')
    },

    {
        img: require('../../../assets/images/gallery/DSC_0101-min.JPG'),
        img_name: 'Modular Walls',
        img_title: 'Tiles/Walls & tiles ',
        url: '/#',
        img_class: 'gallery-item  design  interior',
        placeholderimg: require('../../../assets/images/gallery/dsc_0101_min_thumb.jpg')
    },

    {

        img: require('../../../assets/images/gallery/DSC_0091-min.JPG'),
        img_name: 'Modular Walls',
        img_title: 'Walls/Walls & tiles ',
        url: '/#',
        img_class: 'gallery-item apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0091_min_thumb.jpg')
    },
    {

        img: require('../../../assets/images/gallery/DSC_0103-min.JPG'),
        img_name: 'Commercial Floor',
        img_title: 'Floor /Tiles ',
        url: '/#',
        img_class: 'gallery-item houses apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0103_min_thumb.jpg')
    },

    {
        img: require('../../../assets/images/gallery/DSC_0044-min.JPG'),
        img_name: 'Bathroom Floor',
        img_title: 'Tiles/Floor ',
        url: '/#',
        img_class: 'gallery-item houses apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0044_min_thumb.jpg')
    },

    {
        img: require('../../../assets/images/gallery/DSC_0026-min.JPG'),
        img_name: 'Bath Fitting & Sanitaryware',
        img_title: 'Tiles/Bathroom ',
        url: '/#',
        img_class: 'gallery-item houses apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0026_min_thumb.jpg')
    },
    {

        img: require('../../../assets/images/gallery/DSC_0034-min.JPG'),
        img_name: 'Floor & Marbles',
        img_title: 'Walls/Marbles ',
        url: '/#',
        img_class: 'gallery-item houses apartments',
        placeholderimg: require('../../../assets/images/gallery/dsc_0034_min_thumb.jpg')
    },

];

class Gallery extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section id="sec2">
                                <div className="container">
                                    <div className="gallery-items border-folio-conteainer   vis-por-info three-coulms">
                                        {gallery.map((gal, index) => (
                                            <div className="gallery-item houses apartments">
                                                <div className="grid-item-holder">
                                                    <div className="box-item" key={index}>
                                                        <LazyLoadImage
                                                            alt={gal.img_name}
                                                            src={gal.img}
                                                            effect="blur"
                                                            placeholderSrc={gal.placeholderimg}
                                                            height='300px'
                                                        />
                                                        <div className="overlay" />
                                                    </div>
                                                    <div className="grid-item">
                                                        <h3>
                                                            {gal.img_name}
                                                        </h3>
                                                        <span>{gal.img_title}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <Sidebar title='Gallery' />
            </>
        );
    }
}

export default Gallery;
import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/waterTank/tank1.jpg'),
        capacity: '500 Ltrs',
        dimension: '40inches x 32inches',
        placeholderimg: require('../../../assets/images/shop/waterTank/tank1_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/waterTank/tank2.jpg'),
        capacity: '750 Ltrs',
        dimension: '47inches x 37inches',
        placeholderimg: require('../../../assets/images/shop/waterTank/tank2_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/waterTank/tank3.jpg'),
        capacity: '1000 Ltrs',
        dimension: '50inches x 41inches',
        placeholderimg: require('../../../assets/images/shop/waterTank/tank3_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/waterTank/tank4.jpg'),
        capacity: '1500 Ltrs',
        dimension: '55inches x 49inches',
        placeholderimg: require('../../../assets/images/shop/waterTank/tank4_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/waterTank/tank5.jpg'),
        capacity: '2000 Ltrs',
        dimension: '57inches x 55inches',
        placeholderimg: require('../../../assets/images/shop/waterTank/tank5_thumb.jpg')
    }
]


class WaterTank extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                            <div className="bg" id="Watertank" data-bg={require('../../../assets/images/shop/waterTank/waterTank.png')} data-scrollax="properties: { translateY: '200px' }" style={{ backgroundSize: 'contain'} } />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Water Tank</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Water Tank<br /></h2>
                                        {/* <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains">
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.capacity}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholderimg}
                                                            className='productImg'
                                                        />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4><a href="product-single.html">{product.capacity} </a></h4>
                                                        <div className="product-cats"><a href="#">{product.dimension}</a></div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="cart-overlay" />
                        <div className="cart-modal">
                            <div className="cart-modal-wrap fl-wrap">
                                <span className="close-cart">Close <i className="fa fa-times" /> </span>
                                <h3>Your cart</h3>
                                <ul className="cart-modal-list fl-wrap">
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/4.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">Amazing Lamp</a>
                                            <div className="quantity"><span>1</span> x <span className="woocommerce-Price-amount">$560</span></div>
                                        </div>
                                        <a href="#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/2.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">Metal Wood Chair</a>
                                            <div className="quantity"><span>1</span> x <span className="woocommerce-Price-amount">$170</span></div>
                                        </div>
                                        <a href="#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/3.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">White Sofa</a>
                                            <div className="quantity"><span>2</span> x <span className="woocommerce-Price-amount">$160</span></div>
                                        </div>
                                        <a href="#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                </ul>
                                <div className="cart-modal-total fl-wrap">
                                    <span className="cart-modal-total-title">SUBTOTAL:</span> <span className="woocommerce-Price-amount"> $1050</span>
                                </div>
                                <a href="#" className="btn float-btn flat-btn">View Cart</a>
                                <a href="#" className="btn float-btn flat-btn">Checkout</a>
                            </div>
                        </div>
                    </div>
                    {/* content-holder end */}
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Water Tank' />
            </>
        );
    }
}

export default WaterTank;

import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <>
                <div className="height-emulator" />
                <div id="wrapperFooter">
                    <footer className="content-footer">
                        <div className="footer-inner">
                            <div className="row">
                                <div className="col-md-3">
                                    <a className="footer-logo" href="index.html"><img src={require('../../assets/images/logo.png')} alt="logo" /></a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="footer-header fl-wrap"><h4><span>01.</span>HEAD OFFICE</h4></div>
                                    <div className="footer-box fl-wrap">
                                        <ul>
                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com" className='email'>info@trilokinathagrawalandsons.com</a></li>
                                            <li><span>Phone :</span><a href="/#"><div id="phoneNumber">+919415207421, +919415207721</div></a></li>
                                            <li><span>Address :</span><a href="/#" target="_blank"><div>Shri Bihari Ji Ka Katra</div></a></li>
                                            <li><div id="address">Chowk,Azamgarh</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footer-header fl-wrap">  <h4><span>02.</span>SHOWROOM ADDRESS</h4></div>
                                    <div className="footer-box fl-wrap">
                                        <ul>
                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com" className='email'>info@trilokinathagrawalandsons.com</a></li>
                                            <li><span>Phone :</span><a href="/#"><div id="phoneNumber">+919580555777, +918318658463</div></a></li>
                                            <li><span>Address : </span>Shri Bihari Ji Mandir Road, </li>
                                            <li><div id="address"> Kalinganj, Azamgarh</div></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="footer-header fl-wrap"> <h4><span>03.</span>BRANCH OFFICE</h4></div>
                                    <div className="footer-box fl-wrap">
                                        <ul>
                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com" className='email'>info@trilokinathagrawalandsons.com</a></li>
                                            <li><span>Phone :</span><div id="phoneNumber">+919565127590, +919415207721</div></li>
                                            <li><span>Address : </span>Gorakhpur Bypass</li>
                                            <li><div id="address">Infront of Sunil Petrol Pump,Daulatpur,Azamgarh</div></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                    <div className="fl-wrap policy-box">
                                        <p> &#169; 2019 All Rights Reserved.<a href="https://www.raysteedsinfotech.com" target="_blank" rel="noopener noreferrer" className="pointer"> Raysteeds Infotech Pvt. Ltd. </a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="to-top"><i className="fa fa-long-arrow-up" /></div>
                        </div>
                    </footer>
                </div>
                {/* content-holder end */}
            </>
        )
    }
}

export default Footer;
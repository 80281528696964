import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/voltolight.jpg'),
        name: 'Volto Light',
        type: 'MATT',
        dimension: '300x600mm',
        placeholderImg: require('../../../assets/images/shop/voltolight_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/volto01.jpg'),
        name: 'Volto HL 01',
        type: 'MATT',
        dimension: '300x600mm',
        placeholderImg: require('../../../assets/images/shop/volto01_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/voltodark.jpg'),
        name: 'Volto Dark',
        type: 'MATT',
        dimension: '300x600mm',
        placeholderImg: require('../../../assets/images/shop/voltodark_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/vivo.jpg'),
        name: 'Vivo Mushroom',
        type: 'GLOSSY',
        dimension: '300x600mm',
        placeholderImg: require('../../../assets/images/shop/vivo_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/vitale_beige_light_b.jpg'),
        name: 'Vitale Beige Light',
        type: 'GLOSSY',
        dimension: '300x450mm',
        placeholderImg: require('../../../assets/images/shop/vitale_beige_light_b_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/vitale01.jpg'),
        name: 'Vitale Beige HL 01',
        type: 'GLOSSY',
        dimension: '300x450mm',
        placeholderImg: require('../../../assets/images/shop/vitale01_thumb.jpg')
    },

    {
        img: require('../../../assets/images/shop/vitale_beige_dark_a.jpg'),
        name: 'Vitale Beige Dark',
        type: 'GLOSSY',
        dimension: '300x450mm',
        placeholderImg: require('../../../assets/images/shop/vitale_beige_dark_a_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/visonlight-th.jpg'),
        name: 'Vison Light',
        type: 'GLOSSY',
        dimension: '300x450mm',
        placeholderImg: require('../../../assets/images/shop/visonlight_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/venisegrislight-th.jpg'),
        name: 'Venise Gris Light',
        type: 'GLOSSY',
        dimension: '250x375mm',
        placeholderImg: require('../../../assets/images/shop/venisegrislight_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/venisegrishl1-th.jpg'),
        name: 'Venise Gris HL 1',
        type: 'GLOSSY',
        dimension: '250x375mm',
        placeholderImg: require('../../../assets/images/shop/visonlight_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/venisegrisdark-th.jpg'),
        name: 'Venise Gris Dark',
        type: 'GLOSSY',
        dimension: '250x375mm',
        placeholderImg: require('../../../assets/images/shop/venisegrisdark_th_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/trixnatural-th.jpg'),
        name: 'Trix Natural',
        type: 'MATT',
        dimension: '250x375mm',
        placeholderImg: require('../../../assets/images/shop/trixnatural_th_thumb.jpg')
    },
]

class WallTiles extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/tiles.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Wall Tiles</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Wall Tiles<br /></h2>
                                        <p>Large collection of ceramic wall tiles in Azamgarh.</p>
                                        {/* <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.name}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholderImg}
                                                            className='productImg'
                                                        />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>{product.name}</h4>
                                                        <div className="product-cats"><a href="#">{product.type}</a></div>
                                                        <span className="product-price">Size : {product.dimension}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Wall Tiles' />
            </>
        );
    }
}

export default WallTiles;

import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/pvcFittings/cpvc.png'),
        name: 'CPVC Plumbing System',
        meta: 'Smartfit',
        purpose: 'For hot and Cold Water',
        placeholder: require('../../../assets/images/shop/pvcFittings/cpvc_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/upvc.png'),
        name: 'UPVC Plumbing System',
        meta: 'Smartfit',
        purpose: 'For hot and Cold Water',
        placeholder: require('../../../assets/images/shop/pvcFittings/upvc_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/upvc2.png'),
        name: 'UPVC Drainage Systems',
        meta: 'Drainfit',
        purpose: 'For Underground Drainage piping System',
        placeholder: require('../../../assets/images/shop/pvcFittings/upvc2_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/BFmta.jpg'),
        name: 'UPVC M.T.A',
        meta: 'EasyFit',
        purpose: 'Easy installation',
        placeholder: require('../../../assets/images/shop/pvcFittings/bfmta_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/fta-2.jpg'),
        name: 'UPVC F.T.A',
        meta: 'EasyFit',
        purpose: 'Easy installation',
        placeholder: require('../../../assets/images/shop/pvcFittings/fta_2_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/ft-elbow.jpg'),
        name: 'CPVC F.T. Elbow',
        meta: 'Smartfit',
        purpose: 'For hot and Cold Water',
        placeholder: require('../../../assets/images/shop/pvcFittings/ft_elbow_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/pvcFittings/ball-valleves.jpg'),
        name: 'CPVC Ball Valves',
        meta: 'Smartfit',
        purpose: 'For hot and Cold Water',
        placeholder: require('../../../assets/images/shop/pvcFittings/ball-valleves.jpg')
    },
]

class PvcFittings extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/shop/pvcFittings/Website-Banner-01-min.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>UPVC, CPVC, PVC, FITTINGS</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>UPVC, CPVC, PVC, FITTINGS<br /></h2>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.name}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur"
                                                            placeholderSrc={product.placeholder}
                                                            className='productImg' />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4><a href="product-single.html">{product.name}</a></h4>
                                                        <div className="product-cats"><a href="/#">{product.meta}</a></div>
                                                        <span className="product-price">{product.purpose}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="cart-overlay" />
                        <div className="cart-modal">
                            <div className="cart-modal-wrap fl-wrap">
                                <span className="close-cart">Close <i className="fa fa-times" /> </span>
                                <h3>Your cart</h3>
                                <ul className="cart-modal-list fl-wrap">
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/4.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">Amazing Lamp</a>
                                            <div className="quantity"><span>1</span> x <span className="woocommerce-Price-amount">$560</span></div>
                                        </div>
                                        <a href="/#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/2.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">Metal Wood Chair</a>
                                            <div className="quantity"><span>1</span> x <span className="woocommerce-Price-amount">$170</span></div>
                                        </div>
                                        <a href="/#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                    <li>
                                        <a className="cart-modal-image" href="product-single.html">
                                            <img src="images/shop/3.jpg" alt="" />
                                        </a>
                                        <div className="cart-modal-det">
                                            <a href="product-single.html">White Sofa</a>
                                            <div className="quantity"><span>2</span> x <span className="woocommerce-Price-amount">$160</span></div>
                                        </div>
                                        <a href="/#" className="remove"><i className="fa fa-times" /></a>
                                    </li>
                                </ul>
                                <div className="cart-modal-total fl-wrap">
                                    <span className="cart-modal-total-title">SUBTOTAL:</span> <span className="woocommerce-Price-amount"> $1050</span>
                                </div>
                                <a href="/#" className="btn float-btn flat-btn">View Cart</a>
                                <a href="/#" className="btn float-btn flat-btn">Checkout</a>
                            </div>
                        </div>
                    </div>
                    {/* content-holder end */}
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='UPVC, CPVC, PVC Fittings' />
            </>
        );
    }
}

export default PvcFittings;

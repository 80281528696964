import React, { Component } from 'react';
import Sidebar from '../../core/sidebar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class About extends Component {
  render() {
    return (
      <div id="wrapper">
        <div className="content-holder">
          <div className="content">
            <section id="sec2" data-scrollax-parent="true">
              <div className="container">
                <div className="section-container fl-wrap">
                  <div className="row">
                    <div className="col-md-7">
                      <div className="content-wrap about-wrap">
                        <h3 className="bold-title">About Us</h3>
                        <p>
                          Trilokinath Agrawal & Sons is one of the top retail firm, established since 1931,
                          located in Azamgarh, India. This company is listed in Trade India's list of verified sellers
                          that provide the best design and superior quality of products and always focused on customer
                          satisfaction. We have a vast customer base especially in ceramics, wall and floor tiles and
                          sanitarywares. This company is also focused on educating our staff with the latest product
                          knowledge and about industry marketing so that they can able to provide the best possible
                          service to our customers.
                        </p>
                        <p>
                          Trilokinath Agrawal & Sons is the retailer of Rudraksha Wall & Floor Tiles and SOCH
                          Tiles & Sanitaryware in the Azamgarh location which deals with top brands for tiles,
                          sanitaryware, bathroom fitting, UPVC, CPVC, PVC fittings, water tanks, submersible, geysers
                          and so on. Rudraksha Ceramic was incorporated in the year 1991 by Mr. Raj Kumar Khandelwal
                          and as a production unit holds a capacity of producing 40,000 sqm per day, 5 manufacturing
                          units, a multi-layer distribution network of 2000 dealers and 1000 sub-dealers PAN India.
                        </p>
                        <a
                          href={require('../../../assets/images/Triloklinathagarwalandsons-Brochuer.jpg')}
                          className="btn float-btn flat-btn"
                          download="Brochure - Trilokinath Agrawal & Sons"
                        >
                          Download Brochure
                        </a>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <LazyLoadImage
                        alt='About us'
                        height='auto'
                        src={require('../../../assets/images/aboutus.jpg')}
                        placeholderSrc={require('../../../assets/images/aboutus_thumb.jpg')}
                        width='100%'
                        effect="blur" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg dec-bg left-pos-dec" data-bg={require('../../../assets/images/bg/14.jpg')} />
            </section>
            <section className="parallax-section header-section  " data-scrollax-parent="true" id="sec5">
              <div className="bg" data-bg="images/bg/18.jpg" data-scrollax="properties: { translateY: '200px' }" />
              <div className="overlay" />
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="pr-title">
                      Chairman's Message
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="single-slider testilider fl-wrap" data-effects="slide">
                      <div className="swiper-container">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <div className="testi-item fl-wrap">
                              <h3>Pawan Kumar Agrawal </h3><br /><br />
                              <h5>Chairman</h5>
                              <p>
                                "To be started as a small-scale business since 1931, by connecting vast customers especially
                                in the ceramic sector and now we become one of the top retail firms in the small town Azamgarh"
                              </p>
                              <a href="https://www.facebook.com/vinay.agrawal.37051579" className="btn float-btn flat-btn" target="_blank" rel="noopener noreferrer">
                                Connect Via Facebook
                              </a>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="testi-item fl-wrap">
                              <h3>Gagan Kumar Agrawal</h3><br /><br />
                              <h5>Managing Director</h5>
                              <p>
                                "Being a part of the ceramic business from starting, I focused on managing and marketize it to become
                                the best and top-level retail firm in this industry"
                              </p>
                              <a href="https://www.facebook.com/gagan.agrawal.587" className="btn float-btn flat-btn" target="_blank" rel="noopener noreferrer">
                                Connect Via Facebook
                              </a>
                            </div>
                          </div>
                          <div className="swiper-slide">
                            <div className="testi-item fl-wrap">
                              <h3>Anmol Agrawal</h3><br /><br />
                              <h5>Managing Director</h5>
                              <p>
                                "I'm the luckiest person that I learned from my own business for managing and increasing the business
                                scale in terms of customer satisfaction and supports in the ceramic sector"
                              </p>
                              <a href="https://www.facebook.com/anmol.agrawal.393" className="btn float-btn flat-btn" target="_blank" rel="noopener noreferrer">
                                Connect Via Facebook
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-pagination" />
                        <div className="swiper-button-prev"><i className="fa fa-angle-left" /></div>
                        <div className="swiper-button-next"><i className="fa fa-angle-right" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="sec6">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                  </div>
                </div>
                <div className="custom-inner-holder">
                  <div className="custom-inner">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="cus-inner-title fl-wrap">
                          <h3>Our Vision</h3>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <p>
                          Our Vision is providing a best and valuable design that makes customer satisfaction to build delighting
                          and meaningful relationships. We are focused on a valuable approach is driven by continuous improvement,
                          coherence, and best quality service & support to our customers.
                        </p>
                        <span className="custom-inner-dec" />
                      </div>
                    </div>
                  </div>
                  <div className="custom-inner">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="cus-inner-title fl-wrap">
                          <h3>Our Mission</h3>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <p>
                          Our Mission is to explore our business by distributing finest products & services with best design
                          and superior quality of products, We scale our business by making good communication and connect
                          customers to be leading destination of choice in the ceramic sector.
                        </p>
                        <span className="custom-inner-dec" />
                      </div>
                    </div>
                  </div>
                  <div className="custom-inner">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="cus-inner-title fl-wrap">
                          <h3>Our Values</h3>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <p>
                          We started as a small-scale business since 1931, by connecting vast customers, especially in the ceramic
                          sector by learning the valuable points with our experience that make it helpful to improve our values
                          in the ceramic market. These are:
                        </p>
                        <ul>
                          <li>Morality</li>
                          <li>Efficiency </li>
                          <li>Responsibility</li>
                          <li>Customer Satisfaction </li>
                          <li>Work Environment </li>
                          <li>Loyalty</li>
                        </ul>
                        <span className="custom-inner-dec" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg dec-bg left-pos-dec " data-bg={require('../../../assets/images/bg/15.jpg')} />
            </section>
          </div>
        </div>
        <Sidebar title='About Us' />

      </div>
    );
  }
}

export default About;
import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/EARTHSTONE20BROWN-16-300x300.jpg'),
        name: 'EARTHSTONE BROWN',
        dimension: '400X400 mm',
        placeholderImg: require('../../../assets/images/shop/earthstone20brown_16_0hZa4_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/EARTHSTONE20DARK20GREY-16-300x300.jpg'),
        name: 'EARTHSTONE DARK GREY',
        dimension: '400X400 mm',
        placeholderImg: require('../../../assets/images/shop/earthstone20dark20gr_JaXnR_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/LANDSCAPE-BROWN-300x300.jpg'),
        name: 'LANDSCAPE BROWN',
        dimension: '400X400 mm',
        // placeholderImg: require('../../../assets/images/shop/')
        placeholderImg: require('../../../assets/images/shop/earthstone20dark20gr_JaXnR_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/GRAMITE20BEIGE-16-300x300.jpg'),
        name: 'GRAMITE BEIGE',
        dimension: '300x300mm',
        placeholderImg: require('../../../assets/images/shop/gramite20beige_16_30_YvmVT_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/CHECKS20WALKWAY-16-300x300.jpg'),
        name: 'CHECKS WALKWAY',
        dimension: '300x300mm',
        placeholderImg: require('../../../assets/images/shop/checks20walkway_16_3_5Dqf8thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/LANDSCAPE20VERDE-16-300x300.jpg'),
        name: 'LANDSCAPE VERDE',
        dimension: '300x300mm',
        placeholderImg: require('../../../assets/images/shop/checks20walkway_16_3_5Dqf8thumb.jpg')
    },
]

class ParkingTiles extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/parkingtile.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Parking Tiles</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Parking Tiles<br /></h2>
                                        <p>Large collection of ceramic parking tiles in Azamgarh.</p>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        {
                                            products.map((product, index) => (
                                                <div className="product-cat-mains" key={index}>
                                                    <div className="product-cat-img fl-wrap">
                                                        <LazyLoadImage
                                                            alt={product.type}
                                                            height='200px'
                                                            src={product.img} // use normal <img> attributes as props
                                                            width='100%'
                                                            effect="blur" />
                                                    </div>
                                                    <div className="product-cat-title">
                                                        <h4>{product.name}</h4>
                                                        <div className="product-cats"><a href="#">MATT</a></div>
                                                        <span className="product-price">Size : {product.dimension}</span>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="content-nav">
                                        <ul>
                                            <a href="#/" className="cur-page"><span>End of list </span></a>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Parking Tiles' />
            </>
        );
    }
}

export default ParkingTiles;

import React, { Component } from 'react';
import Sidebar from '../../core/sidebar';
import GoogleMapReact from 'google-map-react';
import * as emailjs from 'emailjs-com';
import mapicon from '../../../assets/images/icons/marker-icon.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const CustomComponent = ({ text }) => <div><img src={mapicon} alt="marker" /></div>;

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            center: {
                lat: 26.072067,
                lng: 83.185654,

            },
            zoom: 10,
            text: 'marker',
            apiKey: 'AIzaSyDTWAxQ7Z4GNC28ljvgSDBAmTiZ-eYiGog',
            submitting: false,
            submitted: false,
            error: false,
            buttonState: '',
            formFields: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                text: ''
            }
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onFieldsChange = this.onFieldsChange.bind(this);
        this.onHideSuccess = this.onHideSuccess.bind(this);
        this.onHideError = this.onHideError.bind(this);
        this.successMessage = this.successMessage.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
    }

    onSubmit(e) {
        let { formFields } = this.state;
        e.preventDefault();
        console.log('ssds', formFields);

        emailjs.send('smtp_server', 'contact_us', formFields, 'user_vke3b2vni4qsaJZ1XDO8M')
            .then((response) => {
                this.setState({ submitted: true, error: false });
            }, (err) => {
                this.setState({ error: true, submitted: false });
            });
        this.setState({ formFields: { name: '', email: '', phone: '', subject: '', text: '' } });
    }

    onFieldsChange(e) {
        let { formFields } = this.state;
        formFields = { ...formFields };
        formFields[e.target.name] = e.target.value;
        this.setState({ formFields });
    }

    onHideSuccess() {
        this.setState({ submitted: false });
    }

    onHideError() {
        this.setState({ error: false });
    }

    successMessage() {
        if (this.state.submitted) {
            return (
                <div className="alert alert-success">
                    <strong>Thank you!</strong>
                    <a href="#/" onClick={this.onHideSuccess} className="fa fa-times">
                    </a>
                </div>
            );
        }
    }

    errorMessage() {
        if (this.state.error) {
            return (
                <div className="alert alert-danger">
                    <strong>Something Went Wrong</strong>
                    <a href="#/" onClick={this.onHideError} className="fa fa-times">
                    </a>
                </div>
            );
        }
    }


    renderGoogleMap() {
        const { center, zoom, text, apiKey } = this.state;
        return (
            <div style={{ height: '55vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    <CustomComponent
                        lat={center.lat}
                        lng={center.lng}
                        text={text}
                    />
                </GoogleMapReact>
            </div>
        );
    }

    render() {
        const { formFields: {
            name,
            email,
            phone,
            subject,
            text,
        } } = this.state;

        return (
            <div id="wrapper">
                <div className="content-holder">
                    <div className="content">
                        <section className="parallax-section header-section" data-scrollax-parent="true">
                            <div className="bg" data-scrollax="properties: { translateY: '200px' }" >
                                <LazyLoadImage
                                    alt='Contact us'
                                    src={require('../../../assets/images/contactus.jpg')}
                                    width='100%'
                                    height='100%'
                                    effect="blur" />
                            </div>
                            <div className="overlay" />
                            <div className="container big-container">
                                <div className="section-title">
                                    <h3>Contact Details</h3>
                                    <div className="separator trsp-separator" />
                                    <h2>Get In Touch <br /> with us</h2>
                                    <a href="#sec1" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="container">
                                <div className="contact-details-wrap fl-wrap" id="sec1">
                                    <div className="row">
                                        <div className="col-md-11" span={24}>
                                            <div className="contact-details fl-wrap">
                                                <div className="row border-dec">
                                                    <div className="col-md-4">
                                                        <h4><span>01.</span>HEAD OFFICE</h4>
                                                        <ul>
                                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com" ><div id="email">info@trilokinathagrawalandsons.com</div></a></li>
                                                            <li><span>Phone :</span><a href="/#"><div id="phoneNumber">+919415207421, +919415207721</div></a></li>
                                                            <li><span>Address :</span><a href="/#" target="_blank"><div>Shri Bihari Ji Ka Katra Chowk,</div></a></li>
                                                            <li><div id="addresstwo">Azamgarh</div></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <h4><span>02.</span>SHOWROOM ADDRESS</h4>
                                                        <ul>
                                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com"><div id="email">info@trilokinathagrawalandsons.com</div></a></li>
                                                            <li><span>Phone :</span><a href="/#"><div id="phoneNumber">+919580555777, +918318658463</div></a></li>
                                                            <li><span>Address : </span>Shri Bihari Ji Mandir Road, </li>
                                                            <li><div id="addresstwo"> Kalinganj, Azamgarh</div></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <h4><span>03.</span>BRANCH OFFICE</h4>
                                                        <ul>
                                                            <li><span>Email :</span><a href="mailto:info@trilokinathagrawalandsons.com"><div id="email">info@trilokinathagrawalandsons.com</div></a></li>
                                                            <li><span>Phone :</span><div id="phoneNumber">+919565127590, +919415207721</div></li>
                                                            <li><span>Address : </span>Gorakhpur Bypass, Infront of  </li>
                                                            <li><div id="addresstwo">Sunil Petrol Pump, Daulatpur,Azamgarh</div></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="map-box">
                                    {this.renderGoogleMap()}
                                </div>

                                <div className="contact-details-wrap fl-wrap">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="small-sec-title">
                                                <h3>Get in Touch  : </h3>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="contact-form-holder fl-wrap">
                                                {this.successMessage()}
                                                {this.errorMessage()}
                                                <div id="contact-form">
                                                    <div id="message" />
                                                    <form name="contactform" id="contactform" onSubmit={this.onSubmit} >
                                                        <input
                                                            name="name"
                                                            type="text"
                                                            id="name"
                                                            placeholder="Name"
                                                            data-error="Please enter your name"
                                                            value={name}
                                                            onChange={this.onFieldsChange} required />

                                                        <input
                                                            name="email"
                                                            type="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            data-error="Please enter your email"
                                                            value={email}
                                                            onChange={this.onFieldsChange} required />

                                                        <input
                                                            type="number"
                                                            name="phone"
                                                            id="phone"
                                                            placeholder="Phone"
                                                            value={phone}
                                                            onChange={this.onFieldsChange} required />

                                                        <input type="text"
                                                            id="subject"
                                                            name="subject"
                                                            placeholder="Subject"
                                                            value={subject}
                                                            onChange={this.onFieldsChange} required />

                                                        <textarea
                                                            name="text"
                                                            id="text"
                                                            data-error="Write your message"
                                                            placeholder="Your Message"
                                                            value={text}
                                                            onChange={this.onFieldsChange} required />
                                                        <button
                                                            type="submit"
                                                            id="submit"
                                                            data-top-bottom="transform: translateY(-50px);"
                                                            data-bottom-top="transform: translateY(50px);"><span>Send Message </span></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="partcile-dec" data-parcount={200} />
                        </section>
                    </div>
                </div>
                <Sidebar title='Contact Us' />
            </div>

        );
    }
}

export default Contact;
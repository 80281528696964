import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Tiles extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <div className="sroll-nav-wrap">
                                <div className="sroll-nav-container">
                                    <nav className="scroll-nav scroll-init fl-wrap">
                                        <ul>
                                            <li><a className="scroll-link act-scrlink" href="#serv1">01.<span>Hero</span></a></li>
                                            <li><a className="scroll-link" href="#serv2">02.<span>Wall</span></a></li>
                                            <li><a className="scroll-link" href="#serv3">03.<span>Floor</span></a></li>
                                            <li><a className="scroll-link" href="#serv4">04.<span>Parking</span></a></li>
                                            <li><a className="scroll-link" href="#serv5">05.<span>Elevation</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <section className="parallax-section header-section" data-scrollax-parent="true" id="serv1">
                                <div className="bg" data-bg={require('../../../assets/images/bg/tiles.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <div className="separator trsp-separator" />
                                        <h2>Tiles</h2>
                                        <p>Large collection of tiles in Azamgarh. Wall tiles, floor tiles, parking tiles, elevation tiles.</p>
                                        {/* <a href="#serv2" className="custom-scroll-link sect-scroll-link"><i className="fa fa-long-arrow-down" /> <span>scroll down</span></a> */}
                                    </div>
                                </div>
                            </section>
                            <section data-scrollax-parent="true">
                                <div className="container">
                                    <div className="section-container  fl-wrap">
                                        <div className="serv-item fl-wrap" id="serv2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="pr-title">
                                                        Wall Tiles
                            <span>Large collection of ceramic wall tiles in Azamgarh. </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="content-wrap about-wrap">
                                                        <div className="blog-media fl-wrap">
                                                            <div className="box-item">
                                                                <LazyLoadImage
                                                                    alt='wall tiles'
                                                                    height='300px'
                                                                    src={require('../../../assets/images/folio/walltile.jpg')} // use normal <img> attributes as props
                                                                    width='100%'
                                                                    effect="blur"
                                                                    className='productImg'
                                                                />
                                                                <div className="overlay" />
                                                                <a href={require('../../../assets/images/folio/walltile.jpg')} className="image-popup popup-image"><i className="fa fa-search" /></a>
                                                            </div>
                                                        </div>
                                                        <h3 className="bold-title">Large collection of ceramic wall tiles in Azamgarh.</h3>
                                                        <a href="/wallTiles" className="btn float-btn flat-btn" style={{ marginTop: "0px" }}>View all</a>
                                                        <div className="clearfix" />
                                                        <span className="bold-separator" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="serv-item fl-wrap" id="serv3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="pr-title">
                                                        Floor Tiles
                            <span>Large collection of ceramic floor tiles in Azamgarh . </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="content-wrap about-wrap">
                                                        <div className="blog-media fl-wrap">
                                                            <div className="box-item">
                                                                <LazyLoadImage
                                                                    alt='floor tiles'
                                                                    height='300px'
                                                                    src={require('../../../assets/images/folio/floortile.jpg')} // use normal <img> attributes as props
                                                                    width='100%'
                                                                    effect="blur"
                                                                    className='productImg'
                                                                />
                                                                <div className="overlay" />
                                                                <a href={require('../../../assets/images/folio/floortile.jpg')} className="image-popup popup-image"><i className="fa fa-search" /></a>
                                                            </div>
                                                        </div>
                                                        <h3 className="bold-title">Large collection of ceramic floor tiles in Azamgarh .  </h3>
                                                        <a href="/floorTiles" className="btn float-btn flat-btn" style={{ marginTop: "0px" }}>View all</a>
                                                        <div className="clearfix" />
                                                        <span className="bold-separator" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="serv-item fl-wrap" id="serv4">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="pr-title">
                                                        Parking Tiles
                            <span>Large collection of ceramic parking tiles in Azamgarh . </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="content-wrap about-wrap">
                                                        <div className="blog-media fl-wrap">
                                                            <div className="box-item">
                                                                <LazyLoadImage
                                                                    alt='parking tiles'
                                                                    height='300px'
                                                                    src={require('../../../assets/images/folio/parkingtile.jpg')} // use normal <img> attributes as props
                                                                    width='100%'
                                                                    effect="blur"
                                                                    className='productImg'
                                                                />
                                                                <div className="overlay" />
                                                                <a href={require('../../../assets/images/folio/parkingtile.jpg')} className="image-popup popup-image"><i className="fa fa-search" /></a>
                                                            </div>
                                                        </div>
                                                        <h3 className="bold-title">Large collection of ceramic parking tiles in Azamgarh . </h3>
                                                        <a href="/parkingTiles" className="btn float-btn flat-btn" style={{ marginTop: "0px" }}>View all</a>
                                                        <div className="clearfix" />
                                                        <span className="bold-separator" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="serv-item fl-wrap" id="serv5">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="pr-title">
                                                        Elevation Tiles
                            <span>Large collection of ceramic elevation tiles in Azamgarh . </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="content-wrap about-wrap">
                                                        <div className="blog-media fl-wrap">
                                                            <div className="box-item">
                                                                <LazyLoadImage
                                                                    alt='Elevation tiles'
                                                                    height='300px'
                                                                    src={require('../../../assets/images/folio/elevationtile.jpg')} // use normal <img> attributes as props
                                                                    width='100%'
                                                                    effect="blur"
                                                                    className='productImg'
                                                                />
                                                                <div className="overlay" />
                                                                <a href={require('../../../assets/images/folio/elevationtile.jpg')} className="image-popup popup-image"><i className="fa fa-search" /></a>
                                                            </div>
                                                        </div>
                                                        <h3 className="bold-title">Large collection of ceramic elevation tiles in Azamgarh . </h3>
                                                        <a href="/elevationTiles" className="btn float-btn flat-btn" style={{ marginTop: "0px" }}>View all</a>
                                                        <div className="clearfix" />
                                                        <span className="bold-separator" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="limit-box fl-wrap" />
                                <div className="partcile-dec" data-parcount={200} />
                            </section>
                        </div>
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title="Tiles" />
            </>
        );
    }
}

export default Tiles;
import React, { Component } from 'react';
import Sidebar from '../../core/sidebar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const products = [
    {
        img: require('../../../assets/images/shop/HILLOCK-GRID-RUST-300x151.jpg'),
        name: 'HILLOCK GRID RUST',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_grid_rust_30_wakWZ_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/HILLOCK-DHOLPUR-GREY-300x151.jpg'),
        name: 'HILLOCK DHOLPUR GREY',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_dholpur_grey_6m0aj_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/HILLOCK-DHOLPUR-BEIGE-300x151.jpg'),
        name: 'HILLOCK DHOLPUR BEIGE',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_dholpur_beig_4q5LW_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/HILLOCK-SLATE-BEIGE-300x151.jpg'),
        name: 'HILLOCK SLATE BEIGE',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_slate_beige__JmPA5_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/HILLOCK-SLATE-RUST-300x151.jpg'),
        name: 'HILLOCK SLATE RUST',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_slate_rust_3_YC8GZ_thumb.jpg')
    },
    {
        img: require('../../../assets/images/shop/HILLOCK-CUBE-GRAPHITE-300x151.jpg'),
        name: 'HILLOCK CUBIC GRAPHITE',
        type: 'MATT',
        desc: 'Size : 300x600mm',
        placeholderImg: require('../../../assets/images/shop/hillock_cube_graphit_obYkP_thumb.jpg')
    },
]

class ElevationTiles extends Component {
    render() {
        return (
            <>
                <div id="wrapper">
                    <div className="content-holder">
                        <div className="content">
                            <section className="parallax-section header-section" data-scrollax-parent="true">
                                <div className="bg" data-bg={require('../../../assets/images/bg/elevationtile.jpg')} data-scrollax="properties: { translateY: '200px' }" />
                                <div className="overlay op1" />
                                <div className="container big-container">
                                    <div className="section-title">
                                        <h3>Elevation Tiles</h3>
                                        <div className="separator trsp-separator" />
                                        <h2>Elevation Tiles<br /></h2>
                                        <p>Large collection of ceramic elevation tiles in Azamgarh.</p>
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className="container">
                                    <div className="products fl-wrap">
                                        <div className="products fl-wrap">
                                            {
                                                products.map((product, index) => (
                                                    <div className="product-cat-mains" key={index}>
                                                        <div className="product-cat-img fl-wrap">
                                                            <LazyLoadImage
                                                                alt={product.type}
                                                                height='200px'
                                                                src={product.img} // use normal <img> attributes as props
                                                                width='100%'
                                                                effect="blur"
                                                                placeholderSrc={product.placeholderImg} />
                                                        </div>
                                                        <div className="product-cat-title">
                                                            <h4>{product.name}</h4>
                                                            <div className="product-cats"><a href="#">MATT</a></div>
                                                            <span className="product-price">Size : 300x600mm</span>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="content-nav">
                                            <ul>
                                                <a href="#/" className="cur-page"><span>End of list </span></a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="height-emulator" />
                    </div>
                </div>
                <div className="search-form-holder fixed-search">
                    <div className="search-form-bg" />
                    <div className="search-form-wrap">
                        <div className="container">
                            <form className="searchform" method="get">
                                <input type="text" autoComplete="off" name="s" placeholder="Type and Enter to Search" />
                            </form>
                            <div className="close-fixed-search" />
                        </div>
                        <div className="dublicated-text" />
                    </div>
                </div>
                <div className="share-wrapper isShare">
                    <div className="share-container" />
                </div>
                <Sidebar title='Elevation Tiles' />
            </>
        );
    }
}

export default ElevationTiles;

import React, { Component } from 'react';

class Navbar extends Component {
    render() {
        return (
            <header className="main-header">
                
                   <div id="rightborder"> <img src={require('../../assets/images/mobile_nav/short-logotwo.png')} alt="short-logo" className="logo-holder-logo"/></div>
                <a className="logo-holder" href="/"><img src={require('../../assets/images/logotwo.png')} alt="logo" /></a>
             
                <div className="nav-button-wrap">
                    <div className="nav-button vis-main-menu"><span /><span /><span /></div>
                </div>
                <div className="nav-holder">
                    <nav>
                        <ul>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/about">About</a>
                            </li>
                            <li>
                                <a>Products</a>
                                <ul>
                                    <li>
                                        <a href="/tiles">Tiles</a>
                                        <ul>
                                            <li><a href="/wallTiles">Wall Tiles</a></li>
                                            <li><a href="/floorTiles">Floor Tiles</a></li>
                                            <li><a href="/parkingTiles">Parking Tiles</a></li>
                                            <li><a href="/elevationTiles">Elevation Tiles</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="/sanitary">SanitaryWare</a></li>
                                    <li><a href="/bathFit">Bathroom Fitting & Wellness</a></li>
                                    <li><a href="/pvcFittings">UPVC, CPVC, PVC Fittings</a></li>
                                    <li><a href="/waterTank">Water Tank</a></li>
                                    <li><a href="/submersible">Submersible</a></li>
                                    <li><a href="/geysers">Geysers</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="/gallery">Gallery</a>
                            </li>
                            <li>
                                <a href="/contact">Contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Navbar;

import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const deals = [
    {
        name: 'cera',
        img: require('../../../assets/images/logos/cera.jpg')
    },
    {
        name: 'crompton',
        img: require('../../../assets/images/logos/crompton-greaves-logo_landscape_full-colour.png')
    },
    {
        name: 'hindware',
        img: require('../../../assets/images/logos/logo (2).png')
    },
    {
        name: 'jaguar',
        img: require('../../../assets/images/logos/download.png')
    },
    {
        name: 'somany',
        img: require('../../../assets/images/logos/somany.png')
    },
    {
        name: 'kajaria',
        img: require('../../../assets/images/logos/logo.png')
    },
    {
        name: 'marc',
        img: require('../../../assets/images/logos/marc-sanitation-pvt-ltd-navrangpura-ahmedabad-sanitaryware-dealers-4ay0rsd.jpg')
    },
    {
        name: 'Prince pipes',
        img: require('../../../assets/images/logos/prince-pipes-logo-new.png')
    },
    {
        name: 'L & K Faucets',
        img: require('../../../assets/images/logos/product-500x500.jpeg')
    }
];

class DealsIN extends Component {
    render() {
        return (
            < section style={{ paddingTop: '0px' }}>
                <div className="container">
                    <div className="pr-title">
                        Trilokinath Deals In
                </div>
                    <div className="clients-list fl-wrap">
                        <ul>
                            {
                                deals.map((deal, index) => (
                                    <li key={index}>
                                        <LazyLoadImage
                                            alt={deal.name}
                                            src={deal.img}
                                            effect="blur"
                                            className='dealimg' />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default DealsIN;
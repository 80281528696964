import React, { Component } from 'react';

class Sidebar extends Component {
    render() {
        return (
            <footer className="main-footer">
                <div className="fixed-title">
                    <img src={require('../../assets/images/short-logo.png')} alt="short-logo" className="short-logo"/>
                    <span>{this.props.title}</span>
                </div>
                <div className="footer-social">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/Triloki-Nath-Agrawal-Sons-1848865641998746" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="/#" target="_blank" rel="noopener noreferrer">
                                <i className="fa fa-instagram" />
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        );
    }
}

export default Sidebar;
